<template>
  <div>
    <v-app-bar app class="colorGradient" elevation="0" v-if="!isPagePDPA">
      <v-btn icon v-if="$vuetify.breakpoint.smAndDown" @click.stop='setIsOpenMenu()'>
        <v-icon color="white">{{menu[0].icon}}</v-icon>
      </v-btn>
      <v-spacer/>
        <img @click="goHome()" style="cursor:pointer;" src='../../../assets/static/images/logo.png'
        :height="$vuetify.breakpoint.xsOnly ? 80 : 90"
        :width="$vuetify.breakpoint.xsOnly ? 100 : 110"
      >
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
        <div class="hidden-sm-and-down transparent" v-if="!isPagePDPA">
          <v-btn v-for="(item, index) in items" :key='index' text rounded color="white" class="mx-3" @click="goDirec(item.name)">
            {{item.title}}
          </v-btn>
          <v-btn text rounded color="white" class="mx-3" href="https://dev-serve.one.th/ticket-form?platform_id=NzExMWY2N2ItZDFlZS00MmFhLWEwZWItNTc4MTM4ZTlhMmFi&owner_service=Y2hhdGJvdA==&service=Y2hhdGJvdA==" target="_blank">
          Open ticket
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text rounded color="white" class="mx-3"
                v-bind="attrs"
                v-on="on"
              >
                Brochure
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in itemsBrochure"
                :key="index" @click="openPDF(item.link)"
              >
                <v-list-item-title>
                  <p :href="item.link" target="_blank">{{ item.title }}</p>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="hidden-sm-and-down transparent" v-if="isPagePDPA">
          <v-btn text rounded color="white" class="mx-3" to="/">
            Home
          </v-btn>
        </div>
      <v-spacer/>
  </v-app-bar>
  <!-- For PDPA -->
  <v-app-bar app class="colorGradient" elevation="0" v-if="isPagePDPA">
    <v-btn text rounded color="white" class="mx-3" to="/">
            <v-icon>mdi-arrow-left</v-icon>ย้อนกลับ
    </v-btn>
    <v-spacer/>
    <img @click="goHome()" style="cursor:pointer;" src='../../../assets/static/images/logo.png'
        :height="$vuetify.breakpoint.xsOnly ? 80 : 90"
        :width="$vuetify.breakpoint.xsOnly ? 100 : 110"
    >
    <v-spacer/>
    <v-spacer v-if="$vuetify.breakpoint.smAndDown"/>
    <v-spacer v-if="$vuetify.breakpoint.smAndDown"/>
  </v-app-bar>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState([
      'isOpenMenu',
      'isPagePDPA'
    ])
  },
  data () {
    return {
      menu: [{ title: 'Menu', icon: 'mdi-menu' }, { title: 'Lang', icon: 'mdi-web' }],
      items: [
        { title: 'Home', icon: 'mdi-cloud-upload-outline', path: '/demo-out', name: 'home' },
        { title: 'About Us', icon: 'mdi-cloud-upload-outline', path: '/about', name: 'about' },
        { title: 'Services', icon: 'mdi-cloud-upload-outline', path: '/service', name: 'service' },
        { title: 'Contact Us', icon: 'mdi-calendar-multiple-check', path: '/contact', name: 'contact' }
      ],
      itemsBrochure: [
        { title: 'Serve Ticket', link: '../img/brochure/ServeTicket.pdf' },
        { title: 'Digital Flow', link: '../img/brochure/DigitalFlow.pdf' }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setIsPagePDPA',
      'setIsOpenMenu'
    ]),
    openPDF (link) {
      // console.log('link :', link)
      window.open(link)
    },
    goDirec (i) {
      console.log(i)
      this.$vuetify.goTo('#' + i)
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
