<template>
  <v-navigation-drawer class="colorGradient" v-model="isOpenMenu" app v-if="$vuetify.breakpoint.smAndDown">
      <v-divider/>
      <v-list>
        <v-list-item v-for="item in items" :key='item.title' @click="goDirec(item.name)">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item href="https://dev-serve.one.th/ticket-form?platform_id=NzExMWY2N2ItZDFlZS00MmFhLWEwZWItNTc4MTM4ZTlhMmFi&owner_service=Y2hhdGJvdA==&service=Y2hhdGJvdA==" target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-ticket</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Open Ticket</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
            <v-list-item-title class="white--text"  v-bind="attrs"
            v-on="on">Brochure</v-list-item-title>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in itemsBrochure"
              :key="index" @click="openPDF(item.link)"
            >
              <v-list-item-title>
                <p :href="item.link" target="_blank">{{ item.title }}</p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState([
      'isOpenMenu'
    ])
  },
  data () {
    return {
      items: [
        { title: 'Home', icon: 'mdi-home', path: '/demo-out', name: 'home' },
        { title: 'About Us', icon: 'mdi-account-group', path: '/about', name: 'about' },
        { title: 'Services', icon: 'mdi-apps', path: '/service', name: 'service' },
        { title: 'Contact Us', icon: 'mdi-card-account-phone', path: '/contact', name: 'contact' }
      ],
      itemsBrochure: [
        { title: 'Serve Ticket', link: '../img/brochure/ServeTicket.pdf' },
        { title: 'Digital Flow', link: '../img/brochure/DigitalFlow.pdf' }
      ]
    }
  },
  methods: {
    goDirec (i) {
      console.log(i)
      this.$vuetify.goTo('#' + i)
    },
    openPDF (link) {
      // console.log('link :', link)
      window.open(link)
    }
  }
}
</script>

<style>

</style>
